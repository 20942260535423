import React from 'react';
import { useHistory } from 'react-router-dom';
import Downshift from 'downshift';
import Box from '../../../react/Box';
import Text from '../../../react/Text';
import Popover from '../../../react/Popover';
import Button from '../../../react/Button';
import List, { ListItem } from '../../../react/List';
import SearchIcon from '../../../react/Icons/Search';
import RemoveIcon from '../../../react/Icons/CrossDisc';
import styles from './SearchInput.css';

import { routes as brandRoutes } from '../../routes/Brand';
import { routes as foundationsRoutes } from '../../routes/Foundations';
import { routes as componentsRoutes } from '../../routes/Components';
import { routes as guidesRoutes } from '../../routes/Guides';

const withBasePath = (data, basePath) => {
  return (
    data.map((item) => {
      return ({
        label: item.label,
        path: `/${basePath.toLowerCase() + item.path + (item.tab || '')}`,
        section: basePath,
      });
    })
  );
};

const items = [
  withBasePath(brandRoutes, 'Brand'),
  withBasePath(foundationsRoutes, 'Foundations'),
  withBasePath(componentsRoutes, 'Components'),
  withBasePath(guidesRoutes, 'Guides'),
].flat()
  // eslint-disable-next-line
  .sort((a, b) => (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0);

const SearchInput = ({ forwardedRef, searchTrigger }) => {
  const history = useHistory();

  const handleChange = (e) => {
    if (!e) return;
    history.push(e.path);
    searchTrigger();
  };

  return (
    <Downshift
      onChange={selection => handleChange(selection)}
      defaultHighlightedIndex={0}
      itemToString={item => (item ? item.label : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        clearSelection,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
      }) => (
        <Popover
          open={isOpen}
          display="block"
          matchTriggerWidth
          {...getRootProps()}
          trigger={() => (
            <Box className={styles.searchContainer}>
              {/* eslint-disable-next-line */}
              <label
                htmlFor="search"
                className={styles.label}
                {...getLabelProps()}
              >
                <Text display="block">Search</Text>
              </label>
              <Box
                display="flex"
                alignItems="center"
              >
                <input
                  className={styles.searchInput}
                  ref={forwardedRef}
                  id="search"
                  placeholder="Search components and guidelines"
                  {...getInputProps()}
                />
                <Box
                  className={styles.searchIcon}
                  marginLeft="m"
                >
                  <SearchIcon />
                </Box>
                {inputValue ? (
                  <Box
                    className={styles.clearIcon}
                    marginRight="xs"
                  >
                    <Button
                      iconBefore={<RemoveIcon color="var(--ds-color-text-muted)" />}
                      circle
                      onClick={clearSelection}
                    />
                  </Box>
                ) : null}
              </Box>
            </Box>
          )}
        >
          <Box className={styles.hits}>
            <List {...getMenuProps()}>
              {isOpen ? (
                items.filter(
                  item => (
                    item.label.toLowerCase().includes(inputValue.toLowerCase())
                  ),
                ).map((item, index) => (
                  <>
                    <ListItem
                      {...getItemProps({
                        key: item.path,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? 'var(--ds-color-state-hover-overlay)' : '',
                        },
                      })}
                    >
                      <Box padding="xxs" paddingX="m">
                        <Text display="block">{item.label}</Text>
                        <Text type="body2" display="block" muted> in {item.section}</Text>
                      </Box>
                    </ListItem>
                  </>
                ))
              ) : null}
            </List>
          </Box>
        </Popover>
      )}
    </Downshift>
  );
};

export default SearchInput;
