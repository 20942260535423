import React, { useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import BannerDividerIcon from '../../../Icons/BannerDivider';
import * as constants from '../../../constants';
import Marquee from '../Header/Marquee';
import styles from './PromoBanner.scss';
import Text from '../../../Text';

const SITEWIDE_BANNER = 'sitewide_banner';

const PromoBanner = ({ banners }) => {
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const containerRef = useRef(null);
  const [banner] = banners || [];
  const bannerMessage = banner && banner.message;

  const combinedRefs = useCallback(
    (node) => {
      containerRef.current = node;
      inViewRef(node);
    },
    [inView],
  );

  React.useEffect(() => {
    if (inView && Array.isArray(banners) && banners.length) {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: 'view_promotion',
        page_location: window.location.href,
        ecommerce: {
          promotion_name: bannerMessage,
          creative_slot: SITEWIDE_BANNER,
        },
      });
    }
  }, [inView]);

  const logEvent = () => {
    try {
      window.dataLayer.push({
        event: 'select_promotion',
        page_location: window.location.href,
        ecommerce: {
          promotion_name: bannerMessage,
          creative_slot: SITEWIDE_BANNER,
        },
      });
    } catch (err) {
      console.error('There was error reporting select_promotion event', err);
    }
  };

  React.useEffect(() => {
    const container = containerRef.current;
    const handleClick = (event) => {
      const link = event.target.closest('a');
      if (link && container && container.contains(link)) {
        logEvent();
      }
    };

    if (bannerMessage && container) {
      container.addEventListener('click', handleClick);
    }

    return () => {
      if (bannerMessage && container) {
        container.removeEventListener('click', handleClick);
      }
    };
  }, [bannerMessage]);

  if (!banners.length) {
    return null;
  }

  return (
    <div className={styles.promoBannerContainer} ref={combinedRefs}>
      <span
        data-testid="reduced-motion-promo-banner"
        className={cnames(styles.wrappingBanner, styles.reducedMotion)}
      >
        <Text type="body2">
          <span
            role="button"
            tabIndex={0}
            dangerouslySetInnerHTML={{
              __html: bannerMessage,
            }}
          />
        </Text>
      </span>
      <span className={styles.marqueeOuterContainer}>
        <Marquee>
          <div
            key="promo-banner"
            data-testid="promo-banner"
            className={styles.promoBanner}
          >
            <Text type="body2">
              <span
                role="button"
                tabIndex={0}
                dangerouslySetInnerHTML={{ __html: bannerMessage }}
              />
            </Text>
            <div className={styles.bannerDivider}>
              <BannerDividerIcon
                size={constants.SMALL}
                className={styles.bannerDivider}
              />
            </div>
          </div>
        </Marquee>
      </span>
    </div>
  );
};

PromoBanner.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ),
};

PromoBanner.defaultProps = {
  banners: [],
};

export default PromoBanner;
