import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import styles from './Marquee.scss';
/**
 *  If we need to extend this component check https://github.com/justin-chu/react-fast-marquee
 *  as it inspired the code here and might already have what we need
 */

const Marquee = ({ children }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [multiplier, setMultiplier] = useState(10);
  const rootRef = useRef(null);
  const marqueeRef = useRef(null);

  if (!children) {
    return null;
  }
  /**
   * We need the width of the container and the marquee to calculate how many copies of
   * our elements we need. If we didn't, there would be a gap at the back of the marquee
   * with nothing in it.
   *
   * E.g if the container is bigger than the marquee, we need to see how many marquees can
   * fit inside the container, then copy the children that many times so it fills the space.
   *
   * If the marquee is bigger than the container, we only need one copy of the children.
   */
  const calculateWidth = useCallback(() => {
    if (marqueeRef.current && rootRef.current) {
      const containerRect = rootRef.current.getBoundingClientRect();
      const marqueeRect = marqueeRef.current.getBoundingClientRect();
      const contWidth = containerRect.width;
      const marqWidth = marqueeRect.width;

      if (contWidth && marqWidth) {
        setMultiplier(
          marqWidth < contWidth ? Math.ceil(contWidth / marqWidth) : 1,
        );
      } else {
        setMultiplier(1);
      }

      setContainerWidth(contWidth);
      setMarqueeWidth(marqWidth);
    }
  }, [rootRef]);

  // Calculate width and multiplier on mount and on window resize
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (marqueeRef.current && rootRef.current) {
      const resizeObserver = new ResizeObserver(() => calculateWidth());
      resizeObserver.observe(rootRef.current);
      resizeObserver.observe(marqueeRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [calculateWidth, rootRef]);

  /**
   *  Animation duration, how big the screen is changes how the marquee moves,
   *  we need to adjust the duration becasue of that
   */
  const duration = useMemo(() => {
    return (marqueeWidth * multiplier) / 50;
  }, [containerWidth, marqueeWidth, multiplier]);

  /**
   * We multiply the children by the multiplier to fill the space
   * We do this because the animation plays at different speeds
   * depending on the size of the screen and the text.
   * Because our banner message can change we need to dynamically
   * handle this, this was the main reason why we needed a more
   * complex solution than a purely css one.
   */
  const multiplyChildren = useCallback(
    (multi) => {
      return Array.from({ length: multi }, (_, outerIndex) => (
        <Fragment key={`marquee-list-item-${outerIndex}`}>
          {children.length > 1 ? (
            children.map((child, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`$multi-child-${outerIndex}-${index}`}>{child}</div>
            ))
          ) : (
            <div key="multi-child-single-child">{children}</div>
          )}
        </Fragment>
      ));
    },
    [children],
  );

  return (
    <div ref={rootRef} className={styles.marqueeContainer}>
      <div className={styles.marquee} style={{ '--duration': `${duration}s` }}>
        <div className={styles.initialChildContainer} ref={marqueeRef}>
          {children.length > 1 ? children.map((child, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`initial-marquee-item-${index}`}>{child}</div>
            );
          })
            : <div key="initial-marquee-item-single">{children}</div>
        }
        </div>
        {multiplyChildren(multiplier - 1)}
      </div>
      {/** *
       *  Need a second copy of the children to follow the first so it appears infinite
       *  When the animation resets the first set of children will take the exact same
       *  space as the second set.
       * */}
      <div className={styles.marquee} style={{ '--duration': `${duration}s` }}>
        {multiplyChildren(multiplier)}
      </div>
    </div>
  );
};

export default Marquee;
