import { SCROLL_DIRECTION } from './constants';

const rafDebouce = (callback) => {
  let requestId;

  return () => {
    if (requestId) {
      cancelAnimationFrame(requestId);
    }

    requestId = requestAnimationFrame(() => {
      callback();
      requestId = null;
    });
  };
};

const setupScrollDirectionListener = (onScrollDirectionChange) => {
  let scrollDirection;
  let prevScrollY = window.scrollY;

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > prevScrollY) {
      scrollDirection = SCROLL_DIRECTION.DOWN;
    } else if (currentScrollY < prevScrollY) {
      scrollDirection = SCROLL_DIRECTION.UP;
    }

    prevScrollY = currentScrollY;
    onScrollDirectionChange(scrollDirection);
  };

  const debounceScrollDirection = rafDebouce(handleScroll);
  window.addEventListener('scroll', debounceScrollDirection);

  return () => window.removeEventListener('scroll', debounceScrollDirection);
};


export default setupScrollDirectionListener;
