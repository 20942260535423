import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import { defineMessages } from 'react-intl';
import * as constants from '../../constants';
import { sortItems, isNavigationItemActive, itemsPropShape } from '../utils';
import PageSection from '../../PageSection';
import Box from '../../Box';
import styles from './styles.scss';
import Item from './Item';
import setupScrollDirectionListener from '../../utils/setupScrollDirectionListener';
import { SCROLL_DIRECTION } from '../../utils/setupScrollDirectionListener/constants';

const messages = defineMessages({
  navigationListLabel: 'Products',
});

const TAB_KEY_CODE = 9;

class ProductNav extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
      lastVisibleSubItemSettings: { height: 0, xPosition: 0 },
      isAnimationEnabled: false,
      scrollDirection: SCROLL_DIRECTION.NONE,
    };

    this.open = this.open.bind(this);
    this.handleSettingsOfSubItem = this.handleSettingsOfSubItem.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.updateScrollDirection = this.updateScrollDirection.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollY = React.createRef();
  }

  open(isOpen) {
    this.setState({ isOpen });
  }

  handleHover() {
    if (!this.state.isAnimationEnabled) {
      this.setState({ isAnimationEnabled: true });
    }
  }

  handleSettingsOfSubItem(settings) {
    const { lastVisibleSubItemSettings } = this.state;
    const {
      height,
      xPosition,
    } = settings;

    if (
      height !== lastVisibleSubItemSettings.height
        || xPosition !== lastVisibleSubItemSettings.xPosition
    ) {
      this.setState({
        lastVisibleSubItemSettings: {
          height,
          xPosition,
        },
      });
    }
  }

  handleKeyDown(e, id) {
    const { focusedItem } = this.state;

    if (e.keyCode === TAB_KEY_CODE && id !== focusedItem) {
      this.setState({ focusedItem: id });
    }
  }

  componentDidMount() {
    if (this.props.items.constructor === Array) {
      for (let i = 0; i < this.props.items.length; i += 1) {
        if (isNavigationItemActive(this.props.items[i])) {
          this.setState({ activeItem: this.props.items[i] });
          break;
        }
      }
    }

    const { peeking } = this.props;

    if (peeking) {
      this.scrollDirectionUpdate = setupScrollDirectionListener(this.updateScrollDirection);
      window.addEventListener('scroll', this.handleScroll);
      this.handleScroll();
    }
  }

  handleScroll() {
    this.scrollY.current = window.scrollY;
    if (this.scrollY.current < this.props.promoBannerHeight) {
      this.setState({ scrollY: this.scrollY.current });
    } else if (this.scrollY.current >= this.props.promoBannerHeight
        && this.state.scrollY !== this.props.promoBannerHeight) {
      this.setState({ scrollY: this.props.promoBannerHeight });
    }
  }

  componentWillUnmount() {
    const { peeking } = this.props;

    if (peeking) {
      this.scrollDirectionUpdate();
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  updateScrollDirection(scrollDirection) {
    if (this.state.scrollDirection !== scrollDirection) {
      this.setState({ scrollDirection });
    }
  }

  render() {
    const {
      intl,
      items: rootItems,
      logEvent,
      peeking,
      onNavItemClick,
      promoBannerHeight,
    } = this.props;
    const {
      isOpen,
      lastVisibleSubItemSettings,
      focusedItem,
      isAnimationEnabled,
      scrollDirection,
    } = this.state;
    const navClass = cnames(styles.nav, {
      [styles.isOpen]: isOpen,
    });

    if (!Array.isArray(rootItems) || !rootItems.length) return null;

    const navStyles = {
      base: {
        position: 'relative',
        backgroundColor: 'var(--ds-color-background-ui)',
        borderBottom: 'var(--ds-size-border-width-small) solid var(--ds-color-border-light)',
      },
      peeking: {
        position: 'absolute',
        width: '100%',
        zIndex: 999999997, // $zAlwaysOnTop - 2
      },
      scrollingUp: {
        position: 'fixed',
        width: '100%',
        transition: 'top 0.2s ease-in, position 0.2s ease-in',
        zIndex: 999999997, // $zAlwaysOnTop - 2,
      },
    };

    const mergedNavStyles = {
      ...navStyles.base,
      ...(peeking ? navStyles.peeking : {}),
      ...(peeking && scrollDirection === SCROLL_DIRECTION.UP ? navStyles.scrollingUp : {}),
    };

    return (
      <>
        <Box
          style={mergedNavStyles}
          className={cnames(styles.navContainer, {
            [styles.transform]: promoBannerHeight !== 0
              && this.scrollY.current >= promoBannerHeight,
          })}
        >
          <PageSection>
            <nav
              className={navClass}
              aria-label={intl.formatMessage(messages.navigationListLabel)}
            >
              <ul
                className={styles.list}
                role="menubar"
              >
                {
                [...rootItems].sort(sortItems).map((rootItem, i) => {
                  const itemProps = {
                    key: rootItem.id,
                    rootItem,
                    settingsOfSubItem: this.handleSettingsOfSubItem,
                    settingsForSubItem: lastVisibleSubItemSettings,
                    isInSecondHalf: i > ((rootItems.length / 2) - 1),
                    handleKeyDown: () => this.handleKeyDown(rootItem.id),
                    open: this.open,
                    handleHover: this.handleHover,
                    isOpen,
                    isAnimationEnabled,
                    logEvent,
                    onNavItemClick,
                  };

                  if (this.state.activeItem === rootItem) {
                    itemProps.isActive = true;
                    itemProps.shouldShowSubItems = rootItem.id === focusedItem;
                  }

                  return (
                    <Item {...itemProps} />
                  );
                })
              }
              </ul>
            </nav>
          </PageSection>
        </Box>
        {/* peeking nav placeholder */}
        { peeking && <Box style={{ position: 'relative', height: constants.DESKTOP_NAV_HEIGHT }} />
        }
      </>
    );
  }
}

ProductNav.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/no-typos
  items: itemsPropShape,
  logEvent: PropTypes.func,
  /**
   * When set to true, nav will hide when scrolling down and show up when scrolling up
   */
  peeking: PropTypes.bool,
  onNavItemClick: PropTypes.func,
};

ProductNav.defaultProps = {
  items: [],
  logEvent: () => {},
};

ProductNav.displayName = 'ProductNav';

export default ProductNav;
